@import "../styles/variables";

.modal-component {
  .overlay {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $spacing-8;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .8);

    @include mobile {padding: 0}
  }

  .modal {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: auto;
    max-height: 100%;
    padding: $spacing-4 0;
    box-shadow: 0 0 #{$spacing-8} 0 gray;
    background: white;
    outline: none;
    overflow-y: auto;

    &.small {@include desktop {max-width: 400px}}

    @include mobile {
      height: 100%;
      padding: $spacing-2 0;
    }
  }

  .modal > .close {
    position: absolute;
    z-index: 2;
    background: white;
    top: $spacing-4;
    right: $spacing-4;

    @include mobile {right: $spacing-2}
  }

  .body {
    padding: 0 $spacing-4;
    overflow-x: hidden;

    @include mobile {
      padding: 0 $spacing-2;
      height: 100%;
    }
  }
}
