@import "../styles/variables";

.transactions-container {
  .repeats,
  .end-date {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: $font-size;
    padding-left: 20px;
  }
  .repeats {background-image: url("../images/icons/bx-repeat-muted.svg")}
  .end-date {background-image: url("../images/icons/bx-calendar-exclamation-muted.svg")}

  .notes > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40vw;

    &.force-ellipsis:after {content: "\02026"}

    @include mobile {max-width: 22.5vw}
  }
}
