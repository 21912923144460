@mixin mobile {
  @media (max-width: 767.9px) {@content}
}
@mixin desktop {
  @media (min-width: 768px) {@content}
}

$font-family: "Open Sans", sans-serif;
$font-size: 16px;

$spacing: 4px;
$spacing-1: $spacing;
$spacing-2: #{$spacing * 2};
$spacing-4: #{$spacing * 4};
$spacing-8: #{$spacing * 8};
$spacing-16: #{$spacing * 16};
$spacing-32: #{$spacing * 32};

$muted: #666;
$muted-2: #999;
$shade: #f2f2f2;
