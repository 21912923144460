.inline {display: inline !important}
.inline-block {display: inline-block !important}
.block {display: block !important}
.table {display: table !important}
.table-cell {display: table-cell !important}
.table-row {display: table-row !important}

@include mobile {
  .mobile-inline {display: inline !important}
  .mobile-inline-block {display: inline-block !important}
  .mobile-block {display: block !important}
  .mobile-table {display: table !important}
  .mobile-table-cell {display: table-cell !important}
  .mobile-table-row {display: table-row !important}
  .desktop-only {display: none}
}

@include desktop {
  .mobile-only {display: none}
}
