@import "variables";

* {
  box-sizing: border-box;
  font-family: $font-family;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  line-height: 1.6;
  font-size: $font-size;
}
