@import "variables";

.full-height { height: 100% }
.full-width { width: 100% }

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: $spacing-8;
  width: $spacing-8;
  height: $spacing-8;
  padding: $spacing-4;
  margin: calc(#{$spacing-2} * -1);
  cursor: pointer;
}
