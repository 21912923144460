@import "_variables.scss";

h1, h2, h3, h4 {font-weight: 600}
h1 {
  font-size: #{$font-size * 1.6};
  margin: 0 0 $spacing-8 0;
}
h2 {
  font-size: #{$font-size * 1.4};
  margin: 0 0 $spacing-4 0;
}
h3 {
  font-size: #{$font-size * 1.2};
  margin: 0 0 $spacing-4 0;
}
h4 {margin: 0 0 $spacing-4 0}

small {font-size: $font-size * .8}

.right {text-align: right}
.center {text-align: center}

.muted {color: $muted}
.muted-2 {color: $muted-2}

.weight-normal {font-weight: 400}
.weight-semibold {font-weight: 600}
.weight-bold {font-weight: 700}

.underline {text-decoration: underline}

.pointer {cursor: pointer}

.nowrap {white-space: nowrap}
