@import "./styles/variables";

.app {
  .menu-toggle {
    position: absolute;
    z-index: 2;
    top: $spacing-1;
    right: 0;
    padding: $spacing-2 $spacing-1 $spacing-2 $spacing-2;
    background: white;
  }
}
