@import "variables";

$input-width: 160px;
$input-width-large: $input-width * 2;
$input-width-small: $input-width / 2;
$input-width-x-small: $input-width / 4;

button,
.btn {
  display: inline-block;
  background: black;
  color: white;
  padding: $spacing-2 $spacing-4;
  border: 1px solid black;
  margin: 0;
  outline: 0;
  text-align: center;
  text-decoration: none;
  font-size: $font-size;

  &:disabled {
    background: #999;
    border: 1px solid #999;
  }

  &.block {
    + button.block,
    + .btn.block {
      margin-left: 0;
      margin-top: $spacing-2;
    }
  }

  &.secondary {
    background: white;
    color: black;
  }

  &.danger {
    background: red;
    border: 1px solid red;
    color: white;
  }

  + button,
  +.btn {margin-left: $spacing}
}

label {
  display: inline-block;
  font-size: $font-size;
  margin-right: $spacing;
  white-space: nowrap;
}

input[type=text],
input[type=tel],
input[type=date],
textarea,
select {
  font-family: "Comic Sans MS", $font-family;
  font-size: $font-size;
  padding: 0;
  margin: 0;
  width: $input-width;
  text-overflow: ellipsis;
  background: transparent;
  border: 0 solid black;
  border-bottom-width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-appearance: none;
  appearance: none;

  &.x-large {@include desktop {width: $input-width * 4}}
  &.large {@include desktop {width: $input-width * 2}}
  &.small {width: $input-width / 2}
  &.x-small {width: $input-width / 4}

  @include mobile {flex-grow: 1}

  + input[type=text],
  + input[type=tel],
  + input[type=date],
  + textarea,
  + select {margin-left: $spacing}
}
textarea {
  resize: none;
  vertical-align: top;
}
input[type=checkbox],
input[type=radio] {margin: 0 $spacing 0 0}

.form {
  margin: calc(#{$spacing-2} * -1) calc(#{$spacing-4} * -1);

  .form-group {
    display: inline-block;
    padding: $spacing-2 $spacing-4;

    @include mobile {
      display: flex;
      align-items: center;
    }
  }
}
