@import "variables";

.table-overflow {
  overflow: auto;
  margin: 0 calc(#{$spacing-2} * -1);
  padding: 0 $spacing-2;
  max-width: calc(100% + #{$spacing-4});
}

table {
  min-width: calc(100% + #{$spacing-4});
  border-collapse: collapse;
  margin: 0 calc(#{$spacing-2} * -1);
  overflow: auto;
  line-height: 1.4;

  &.striped {
    tbody tr:nth-child(2n) {background: $shade}
  }
}

tbody + tbody {
  &:before {
    content: "";
    display: block;
    height: $spacing-4;
  }
}

thead {border-bottom: 1px solid $muted}

tbody + thead {
  > tr:first-child {
    > th {padding-top: $spacing-16}
  }
}

th {
  padding: $spacing-2;
  text-align: left;
  font-weight: 600;
  vertical-align: top;

  &.sortable {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: right $spacing-2 top calc(#{$spacing-2} + 4px);
    background-size: $font-size * .8;
    padding-right: calc(#{$spacing-2} + 19px);

    &.sort-asc {background-image: url("../../node_modules/boxicons/svg/solid/bxs-up-arrow.svg")}
    &.sort-desc {background-image: url("../../node_modules/boxicons/svg/solid/bxs-down-arrow.svg")}
  }
}

td {
  padding: $spacing-2;
  vertical-align: top;

  &.middle {vertical-align: middle}
}
