@import "variables";

@each $i in (0, 1, 2, 4, 8, 16, 32) {
  .m-#{$i} {margin: #{$spacing * $i}}
  .mt-#{$i} {margin-top: #{$spacing * $i}}
  .mb-#{$i} {margin-bottom: #{$spacing * $i}}
  .ml-#{$i} {margin-left: #{$spacing * $i}}
  .mr-#{$i} {margin-right: #{$spacing * $i}}
  .mx-#{$i} {
    margin-left: #{$spacing * $i};
    margin-right: #{$spacing * $i};
  }
  .my-#{$i} {
    margin-top: #{$spacing * $i};
    margin-bottom: #{$spacing * $i};
  }

  .p-#{$i} {padding: #{$spacing * $i}}
  .pt-#{$i} {padding-top: #{$spacing * $i}}
  .pb-#{$i} {padding-bottom: #{$spacing * $i}}
  .pl-#{$i} {padding-left: #{$spacing * $i}}
  .pr-#{$i} {padding-right: #{$spacing * $i}}
  .px-#{$i} {
    padding-left: #{$spacing * $i};
    padding-right: #{$spacing * $i};
  }
  .py-#{$i} {
    padding-top: #{$spacing * $i};
    padding-bottom: #{$spacing * $i};
  }

  @include mobile {
    .mobile-m-#{$i} {margin: #{$spacing * $i}}
    .mobile-mt-#{$i} {margin-top: #{$spacing * $i}}
    .mobile-mb-#{$i} {margin-bottom: #{$spacing * $i}}
    .mobile-ml-#{$i} {margin-left: #{$spacing * $i}}
    .mobile-mr-#{$i} {margin-right: #{$spacing * $i}}
    .mobile-mx-#{$i} {
      margin-left: #{$spacing * $i};
      margin-right: #{$spacing * $i};
    }
    .mobile-my-#{$i} {
      margin-top: #{$spacing * $i};
      margin-bottom: #{$spacing * $i};
    }

    .mobile-p-#{$i} {padding: #{$spacing * $i}}
    .mobile-pt-#{$i} {padding-top: #{$spacing * $i}}
    .mobile-pb-#{$i} {padding-bottom: #{$spacing * $i}}
    .mobile-pl-#{$i} {padding-left: #{$spacing * $i}}
    .mobile-pr-#{$i} {padding-right: #{$spacing * $i}}
    .mobile-px-#{$i} {
      padding-left: #{$spacing * $i};
      padding-right: #{$spacing * $i};
    }
    .mobile-py-#{$i} {
      padding-top: #{$spacing * $i};
      padding-bottom: #{$spacing * $i};
    }
  }
}

.m-auto {margin: auto}
.mt-auto {margin-top: auto}
.mb-auto {margin-bottom: auto}
.ml-auto {margin-left: auto}
.mr-auto {margin-right: auto}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@include mobile {
  .mobile-m-auto {margin: auto}
  .mobile-mt-auto {margin-top: auto}
  .mobile-mb-auto {margin-bottom: auto}
  .mobile-ml-auto {margin-left: auto}
  .mobile-mr-auto {margin-right: auto}
  .mobile-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mobile-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
